import React from "react";

import "./HomeSVG.css";

function HomeSVG() {
    const logo = document.querySelectorAll("#dev-logo path");

    for (let i = 0; i < logo.length; i++) {
        console.log(`Letter ${i} is ${logo[i].getTotalLength()}`);
    }

    return (
        <svg
            id="dev-logo"
            width="1152"
            height="143"
            viewBox="0 0 1152 143"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="path-1-outside-1_101_5"
                maskUnits="userSpaceOnUse"
                x="-0.703979"
                y="0.208008"
                width="1153"
                height="143"
                fill="black"
            >
                <rect
                    fill="white"
                    x="-0.703979"
                    y="0.208008"
                    width="1153"
                    height="143"
                />
                <path d="M36.144 103.896C30.256 103.896 24.9654 102.872 20.272 100.824C15.664 98.6907 12.0374 95.7893 9.39202 92.12C6.74669 88.3653 5.38135 84.056 5.29602 79.192H17.712C18.1387 83.3733 19.8454 86.9147 22.832 89.816C25.904 92.632 30.3414 94.04 36.144 94.04C41.6907 94.04 46.0427 92.6747 49.2 89.944C52.4427 87.128 54.064 83.544 54.064 79.192C54.064 75.7787 53.1254 73.0053 51.248 70.872C49.3707 68.7387 47.024 67.1173 44.208 66.008C41.392 64.8987 37.5947 63.704 32.816 62.424C26.928 60.888 22.192 59.352 18.608 57.816C15.1094 56.28 12.08 53.8907 9.52002 50.648C7.04535 47.32 5.80802 42.8827 5.80802 37.336C5.80802 32.472 7.04535 28.1627 9.52002 24.408C11.9947 20.6533 15.4507 17.752 19.888 15.704C24.4107 13.656 29.5734 12.632 35.376 12.632C43.7387 12.632 50.5654 14.7227 55.856 18.904C61.232 23.0853 64.2614 28.632 64.944 35.544H52.144C51.7174 32.1307 49.9254 29.144 46.768 26.584C43.6107 23.9387 39.4294 22.616 34.224 22.616C29.36 22.616 25.392 23.896 22.32 26.456C19.248 28.9307 17.712 32.4293 17.712 36.952C17.712 40.1947 18.608 42.84 20.4 44.888C22.2774 46.936 24.5387 48.5147 27.184 49.624C29.9147 50.648 33.712 51.8427 38.576 53.208C44.464 54.8293 49.2 56.4507 52.784 58.072C56.368 59.608 59.44 62.04 62 65.368C64.56 68.6107 65.84 73.048 65.84 78.68C65.84 83.032 64.688 87.128 62.384 90.968C60.08 94.808 56.6667 97.9227 52.144 100.312C47.6214 102.701 42.288 103.896 36.144 103.896Z" />
                <path d="M113.573 104.152C107.002 104.152 101.029 102.659 95.653 99.672C90.3624 96.6853 86.181 92.4613 83.109 87C80.1224 81.4533 78.629 75.0534 78.629 67.8C78.629 60.632 80.165 54.3173 83.237 48.856C86.3944 43.3093 90.661 39.0853 96.037 36.184C101.413 33.1973 107.429 31.704 114.085 31.704C120.741 31.704 126.757 33.1973 132.133 36.184C137.509 39.0853 141.733 43.2667 144.805 48.728C147.962 54.1893 149.541 60.5467 149.541 67.8C149.541 75.0534 147.92 81.4533 144.677 87C141.52 92.4613 137.21 96.6853 131.749 99.672C126.288 102.659 120.229 104.152 113.573 104.152ZM113.573 93.912C117.754 93.912 121.68 92.9307 125.349 90.968C129.018 89.0054 131.962 86.0613 134.181 82.136C136.485 78.2107 137.637 73.432 137.637 67.8C137.637 62.168 136.528 57.3893 134.309 53.464C132.09 49.5387 129.189 46.6373 125.605 44.76C122.021 42.7973 118.138 41.816 113.957 41.816C109.69 41.816 105.765 42.7973 102.181 44.76C98.6824 46.6373 95.8664 49.5387 93.733 53.464C91.5997 57.3893 90.533 62.168 90.533 67.8C90.533 73.5174 91.557 78.3387 93.605 82.264C95.7384 86.1893 98.5544 89.1333 102.053 91.096C105.552 92.9733 109.392 93.912 113.573 93.912Z" />
                <path d="M193.4 42.456H178.68V103H167.032V42.456H157.944V32.856H167.032V27.864C167.032 20.0133 169.037 14.296 173.048 10.712C177.144 7.04268 183.672 5.20801 192.632 5.20801V14.936C187.512 14.936 183.885 15.96 181.752 18.008C179.704 19.9707 178.68 23.256 178.68 27.864V32.856H193.4V42.456Z" />
                <path d="M221.189 42.456V83.8C221.189 87.2134 221.914 89.6453 223.365 91.096C224.816 92.4613 227.333 93.144 230.917 93.144H239.493V103H228.997C222.512 103 217.648 101.507 214.405 98.52C211.162 95.5334 209.541 90.6267 209.541 83.8V42.456H200.453V32.856H209.541V15.192H221.189V32.856H239.493V42.456H221.189Z" />
                <path d="M347.046 32.856L325.158 103H313.126L296.23 47.32L279.334 103H267.302L245.286 32.856H257.19L273.318 91.736L290.726 32.856H302.63L319.654 91.864L335.526 32.856H347.046Z" />
                <path d="M354.254 67.672C354.254 60.504 355.705 54.232 358.606 48.856C361.507 43.3947 365.475 39.1707 370.51 36.184C375.63 33.1973 381.305 31.704 387.534 31.704C393.678 31.704 399.011 33.0267 403.534 35.672C408.057 38.3173 411.427 41.6453 413.646 45.656V32.856H425.422V103H413.646V89.944C411.342 94.04 407.886 97.4534 403.278 100.184C398.755 102.829 393.465 104.152 387.406 104.152C381.177 104.152 375.545 102.616 370.51 99.544C365.475 96.472 361.507 92.1627 358.606 86.616C355.705 81.0693 354.254 74.7547 354.254 67.672ZM413.646 67.8C413.646 62.5094 412.579 57.9013 410.446 53.976C408.313 50.0507 405.411 47.064 401.742 45.016C398.158 42.8827 394.19 41.816 389.838 41.816C385.486 41.816 381.518 42.84 377.934 44.888C374.35 46.936 371.491 49.9227 369.358 53.848C367.225 57.7733 366.158 62.3813 366.158 67.672C366.158 73.048 367.225 77.7413 369.358 81.752C371.491 85.6773 374.35 88.7067 377.934 90.84C381.518 92.888 385.486 93.912 389.838 93.912C394.19 93.912 398.158 92.888 401.742 90.84C405.411 88.7067 408.313 85.6773 410.446 81.752C412.579 77.7413 413.646 73.0907 413.646 67.8Z" />
                <path d="M456.754 44.248C458.802 40.2373 461.703 37.1227 465.458 34.904C469.298 32.6853 473.949 31.576 479.41 31.576V43.608H476.338C463.282 43.608 456.754 50.6907 456.754 64.856V103H445.106V32.856H456.754V44.248Z" />
                <path d="M556.856 65.24C556.856 67.4587 556.728 69.8053 556.472 72.28H500.408C500.835 79.192 503.181 84.6107 507.448 88.536C511.8 92.376 517.048 94.296 523.192 94.296C528.227 94.296 532.408 93.144 535.736 90.84C539.149 88.4507 541.539 85.2933 542.904 81.368H555.448C553.571 88.1093 549.816 93.6134 544.184 97.88C538.552 102.061 531.555 104.152 523.192 104.152C516.536 104.152 510.563 102.659 505.272 99.672C500.067 96.6853 495.971 92.4613 492.984 87C489.997 81.4533 488.504 75.0534 488.504 67.8C488.504 60.5467 489.955 54.1893 492.856 48.728C495.757 43.2667 499.811 39.0853 505.016 36.184C510.307 33.1973 516.365 31.704 523.192 31.704C529.848 31.704 535.736 33.1547 540.856 36.056C545.976 38.9573 549.901 42.968 552.632 48.088C555.448 53.1227 556.856 58.84 556.856 65.24ZM544.824 62.808C544.824 58.3707 543.843 54.5733 541.88 51.416C539.917 48.1734 537.229 45.7413 533.816 44.12C530.488 42.4133 526.776 41.56 522.68 41.56C516.792 41.56 511.757 43.4374 507.576 47.192C503.48 50.9467 501.133 56.152 500.536 62.808H544.824Z" />
                <path d="M618.004 23.256V53.08H650.516V62.68H618.004V93.4H654.356V103H606.356V13.656H654.356V23.256H618.004Z" />
                <path d="M706.157 31.576C714.69 31.576 721.602 34.1787 726.893 39.384C732.184 44.504 734.829 51.928 734.829 61.656V103H723.309V63.32C723.309 56.3227 721.56 50.9893 718.061 47.32C714.562 43.5653 709.784 41.688 703.725 41.688C697.581 41.688 692.674 43.608 689.005 47.448C685.421 51.288 683.629 56.8773 683.629 64.216V103H671.981V32.856H683.629V42.84C685.933 39.256 689.048 36.4827 692.973 34.52C696.984 32.5573 701.378 31.576 706.157 31.576Z" />
                <path d="M782.784 31.704C788.843 31.704 794.133 33.0267 798.656 35.672C803.264 38.3173 806.677 41.6453 808.896 45.656V32.856H820.672V104.536C820.672 110.936 819.307 116.611 816.576 121.56C813.845 126.595 809.92 130.52 804.8 133.336C799.765 136.152 793.877 137.56 787.136 137.56C777.92 137.56 770.24 135.384 764.096 131.032C757.952 126.68 754.325 120.749 753.216 113.24H764.736C766.016 117.507 768.661 120.92 772.672 123.48C776.683 126.125 781.504 127.448 787.136 127.448C793.536 127.448 798.741 125.443 802.752 121.432C806.848 117.421 808.896 111.789 808.896 104.536V89.816C806.592 93.912 803.179 97.3253 798.656 100.056C794.133 102.787 788.843 104.152 782.784 104.152C776.555 104.152 770.88 102.616 765.76 99.544C760.725 96.472 756.757 92.1627 753.856 86.616C750.955 81.0693 749.504 74.7547 749.504 67.672C749.504 60.504 750.955 54.232 753.856 48.856C756.757 43.3947 760.725 39.1707 765.76 36.184C770.88 33.1973 776.555 31.704 782.784 31.704ZM808.896 67.8C808.896 62.5094 807.829 57.9013 805.696 53.976C803.563 50.0507 800.661 47.064 796.992 45.016C793.408 42.8827 789.44 41.816 785.088 41.816C780.736 41.816 776.768 42.84 773.184 44.888C769.6 46.936 766.741 49.9227 764.608 53.848C762.475 57.7733 761.408 62.3813 761.408 67.672C761.408 73.048 762.475 77.7413 764.608 81.752C766.741 85.6773 769.6 88.7067 773.184 90.84C776.768 92.888 780.736 93.912 785.088 93.912C789.44 93.912 793.408 92.888 796.992 90.84C800.661 88.7067 803.563 85.6773 805.696 81.752C807.829 77.7413 808.896 73.0907 808.896 67.8Z" />
                <path d="M846.372 21.464C844.153 21.464 842.276 20.696 840.74 19.16C839.204 17.624 838.436 15.7467 838.436 13.528C838.436 11.3093 839.204 9.43201 840.74 7.89601C842.276 6.36001 844.153 5.59201 846.372 5.59201C848.505 5.59201 850.297 6.36001 851.748 7.89601C853.284 9.43201 854.052 11.3093 854.052 13.528C854.052 15.7467 853.284 17.624 851.748 19.16C850.297 20.696 848.505 21.464 846.372 21.464ZM852.004 32.856V103H840.356V32.856H852.004Z" />
                <path d="M906.032 31.576C914.565 31.576 921.477 34.1787 926.768 39.384C932.059 44.504 934.704 51.928 934.704 61.656V103H923.184V63.32C923.184 56.3227 921.435 50.9893 917.936 47.32C914.437 43.5653 909.659 41.688 903.6 41.688C897.456 41.688 892.549 43.608 888.88 47.448C885.296 51.288 883.504 56.8773 883.504 64.216V103H871.856V32.856H883.504V42.84C885.808 39.256 888.923 36.4827 892.848 34.52C896.859 32.5573 901.253 31.576 906.032 31.576Z" />
                <path d="M1017.73 65.24C1017.73 67.4587 1017.6 69.8053 1017.35 72.28H961.283C961.71 79.192 964.056 84.6107 968.323 88.536C972.675 92.376 977.923 94.296 984.067 94.296C989.102 94.296 993.283 93.144 996.611 90.84C1000.02 88.4507 1002.41 85.2933 1003.78 81.368H1016.32C1014.45 88.1093 1010.69 93.6134 1005.06 97.88C999.427 102.061 992.43 104.152 984.067 104.152C977.411 104.152 971.438 102.659 966.147 99.672C960.942 96.6853 956.846 92.4613 953.859 87C950.872 81.4533 949.379 75.0534 949.379 67.8C949.379 60.5467 950.83 54.1893 953.731 48.728C956.632 43.2667 960.686 39.0853 965.891 36.184C971.182 33.1973 977.24 31.704 984.067 31.704C990.723 31.704 996.611 33.1547 1001.73 36.056C1006.85 38.9573 1010.78 42.968 1013.51 48.088C1016.32 53.1227 1017.73 58.84 1017.73 65.24ZM1005.7 62.808C1005.7 58.3707 1004.72 54.5733 1002.76 51.416C1000.79 48.1734 998.104 45.7413 994.691 44.12C991.363 42.4133 987.651 41.56 983.555 41.56C977.667 41.56 972.632 43.4374 968.451 47.192C964.355 50.9467 962.008 56.152 961.411 62.808H1005.7Z" />
                <path d="M1097.11 65.24C1097.11 67.4587 1096.98 69.8053 1096.72 72.28H1040.66C1041.08 79.192 1043.43 84.6107 1047.7 88.536C1052.05 92.376 1057.3 94.296 1063.44 94.296C1068.48 94.296 1072.66 93.144 1075.99 90.84C1079.4 88.4507 1081.79 85.2933 1083.15 81.368H1095.7C1093.82 88.1093 1090.07 93.6134 1084.43 97.88C1078.8 102.061 1071.8 104.152 1063.44 104.152C1056.79 104.152 1050.81 102.659 1045.52 99.672C1040.32 96.6853 1036.22 92.4613 1033.23 87C1030.25 81.4533 1028.75 75.0534 1028.75 67.8C1028.75 60.5467 1030.2 54.1893 1033.11 48.728C1036.01 43.2667 1040.06 39.0853 1045.27 36.184C1050.56 33.1973 1056.62 31.704 1063.44 31.704C1070.1 31.704 1075.99 33.1547 1081.11 36.056C1086.23 38.9573 1090.15 42.968 1092.88 48.088C1095.7 53.1227 1097.11 58.84 1097.11 65.24ZM1085.07 62.808C1085.07 58.3707 1084.09 54.5733 1082.13 51.416C1080.17 48.1734 1077.48 45.7413 1074.07 44.12C1070.74 42.4133 1067.03 41.56 1062.93 41.56C1057.04 41.56 1052.01 43.4374 1047.83 47.192C1043.73 50.9467 1041.38 56.152 1040.79 62.808H1085.07Z" />
                <path d="M1124.13 44.248C1126.18 40.2373 1129.08 37.1227 1132.83 34.904C1136.67 32.6853 1141.32 31.576 1146.79 31.576V43.608H1143.71C1130.66 43.608 1124.13 50.6907 1124.13 64.856V103H1112.48V32.856H1124.13V44.248Z" />
            </mask>
            <path
                d="M36.144 103.896C30.256 103.896 24.9654 102.872 20.272 100.824C15.664 98.6907 12.0374 95.7893 9.39202 92.12C6.74669 88.3653 5.38135 84.056 5.29602 79.192H17.712C18.1387 83.3733 19.8454 86.9147 22.832 89.816C25.904 92.632 30.3414 94.04 36.144 94.04C41.6907 94.04 46.0427 92.6747 49.2 89.944C52.4427 87.128 54.064 83.544 54.064 79.192C54.064 75.7787 53.1254 73.0053 51.248 70.872C49.3707 68.7387 47.024 67.1173 44.208 66.008C41.392 64.8987 37.5947 63.704 32.816 62.424C26.928 60.888 22.192 59.352 18.608 57.816C15.1094 56.28 12.08 53.8907 9.52002 50.648C7.04535 47.32 5.80802 42.8827 5.80802 37.336C5.80802 32.472 7.04535 28.1627 9.52002 24.408C11.9947 20.6533 15.4507 17.752 19.888 15.704C24.4107 13.656 29.5734 12.632 35.376 12.632C43.7387 12.632 50.5654 14.7227 55.856 18.904C61.232 23.0853 64.2614 28.632 64.944 35.544H52.144C51.7174 32.1307 49.9254 29.144 46.768 26.584C43.6107 23.9387 39.4294 22.616 34.224 22.616C29.36 22.616 25.392 23.896 22.32 26.456C19.248 28.9307 17.712 32.4293 17.712 36.952C17.712 40.1947 18.608 42.84 20.4 44.888C22.2774 46.936 24.5387 48.5147 27.184 49.624C29.9147 50.648 33.712 51.8427 38.576 53.208C44.464 54.8293 49.2 56.4507 52.784 58.072C56.368 59.608 59.44 62.04 62 65.368C64.56 68.6107 65.84 73.048 65.84 78.68C65.84 83.032 64.688 87.128 62.384 90.968C60.08 94.808 56.6667 97.9227 52.144 100.312C47.6214 102.701 42.288 103.896 36.144 103.896Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M113.573 104.152C107.002 104.152 101.029 102.659 95.653 99.672C90.3624 96.6853 86.181 92.4613 83.109 87C80.1224 81.4533 78.629 75.0534 78.629 67.8C78.629 60.632 80.165 54.3173 83.237 48.856C86.3944 43.3093 90.661 39.0853 96.037 36.184C101.413 33.1973 107.429 31.704 114.085 31.704C120.741 31.704 126.757 33.1973 132.133 36.184C137.509 39.0853 141.733 43.2667 144.805 48.728C147.962 54.1893 149.541 60.5467 149.541 67.8C149.541 75.0534 147.92 81.4533 144.677 87C141.52 92.4613 137.21 96.6853 131.749 99.672C126.288 102.659 120.229 104.152 113.573 104.152ZM113.573 93.912C117.754 93.912 121.68 92.9307 125.349 90.968C129.018 89.0054 131.962 86.0613 134.181 82.136C136.485 78.2107 137.637 73.432 137.637 67.8C137.637 62.168 136.528 57.3893 134.309 53.464C132.09 49.5387 129.189 46.6373 125.605 44.76C122.021 42.7973 118.138 41.816 113.957 41.816C109.69 41.816 105.765 42.7973 102.181 44.76C98.6824 46.6373 95.8664 49.5387 93.733 53.464C91.5997 57.3893 90.533 62.168 90.533 67.8C90.533 73.5174 91.557 78.3387 93.605 82.264C95.7384 86.1893 98.5544 89.1333 102.053 91.096C105.552 92.9733 109.392 93.912 113.573 93.912Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M193.4 42.456H178.68V103H167.032V42.456H157.944V32.856H167.032V27.864C167.032 20.0133 169.037 14.296 173.048 10.712C177.144 7.04268 183.672 5.20801 192.632 5.20801V14.936C187.512 14.936 183.885 15.96 181.752 18.008C179.704 19.9707 178.68 23.256 178.68 27.864V32.856H193.4V42.456Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M221.189 42.456V83.8C221.189 87.2134 221.914 89.6453 223.365 91.096C224.816 92.4613 227.333 93.144 230.917 93.144H239.493V103H228.997C222.512 103 217.648 101.507 214.405 98.52C211.162 95.5334 209.541 90.6267 209.541 83.8V42.456H200.453V32.856H209.541V15.192H221.189V32.856H239.493V42.456H221.189Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M347.046 32.856L325.158 103H313.126L296.23 47.32L279.334 103H267.302L245.286 32.856H257.19L273.318 91.736L290.726 32.856H302.63L319.654 91.864L335.526 32.856H347.046Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M354.254 67.672C354.254 60.504 355.705 54.232 358.606 48.856C361.507 43.3947 365.475 39.1707 370.51 36.184C375.63 33.1973 381.305 31.704 387.534 31.704C393.678 31.704 399.011 33.0267 403.534 35.672C408.057 38.3173 411.427 41.6453 413.646 45.656V32.856H425.422V103H413.646V89.944C411.342 94.04 407.886 97.4534 403.278 100.184C398.755 102.829 393.465 104.152 387.406 104.152C381.177 104.152 375.545 102.616 370.51 99.544C365.475 96.472 361.507 92.1627 358.606 86.616C355.705 81.0693 354.254 74.7547 354.254 67.672ZM413.646 67.8C413.646 62.5094 412.579 57.9013 410.446 53.976C408.313 50.0507 405.411 47.064 401.742 45.016C398.158 42.8827 394.19 41.816 389.838 41.816C385.486 41.816 381.518 42.84 377.934 44.888C374.35 46.936 371.491 49.9227 369.358 53.848C367.225 57.7733 366.158 62.3813 366.158 67.672C366.158 73.048 367.225 77.7413 369.358 81.752C371.491 85.6773 374.35 88.7067 377.934 90.84C381.518 92.888 385.486 93.912 389.838 93.912C394.19 93.912 398.158 92.888 401.742 90.84C405.411 88.7067 408.313 85.6773 410.446 81.752C412.579 77.7413 413.646 73.0907 413.646 67.8Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M456.754 44.248C458.802 40.2373 461.703 37.1227 465.458 34.904C469.298 32.6853 473.949 31.576 479.41 31.576V43.608H476.338C463.282 43.608 456.754 50.6907 456.754 64.856V103H445.106V32.856H456.754V44.248Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M556.856 65.24C556.856 67.4587 556.728 69.8053 556.472 72.28H500.408C500.835 79.192 503.181 84.6107 507.448 88.536C511.8 92.376 517.048 94.296 523.192 94.296C528.227 94.296 532.408 93.144 535.736 90.84C539.149 88.4507 541.539 85.2933 542.904 81.368H555.448C553.571 88.1093 549.816 93.6134 544.184 97.88C538.552 102.061 531.555 104.152 523.192 104.152C516.536 104.152 510.563 102.659 505.272 99.672C500.067 96.6853 495.971 92.4613 492.984 87C489.997 81.4533 488.504 75.0534 488.504 67.8C488.504 60.5467 489.955 54.1893 492.856 48.728C495.757 43.2667 499.811 39.0853 505.016 36.184C510.307 33.1973 516.365 31.704 523.192 31.704C529.848 31.704 535.736 33.1547 540.856 36.056C545.976 38.9573 549.901 42.968 552.632 48.088C555.448 53.1227 556.856 58.84 556.856 65.24ZM544.824 62.808C544.824 58.3707 543.843 54.5733 541.88 51.416C539.917 48.1734 537.229 45.7413 533.816 44.12C530.488 42.4133 526.776 41.56 522.68 41.56C516.792 41.56 511.757 43.4374 507.576 47.192C503.48 50.9467 501.133 56.152 500.536 62.808H544.824Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M618.004 23.256V53.08H650.516V62.68H618.004V93.4H654.356V103H606.356V13.656H654.356V23.256H618.004Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M706.157 31.576C714.69 31.576 721.602 34.1787 726.893 39.384C732.184 44.504 734.829 51.928 734.829 61.656V103H723.309V63.32C723.309 56.3227 721.56 50.9893 718.061 47.32C714.562 43.5653 709.784 41.688 703.725 41.688C697.581 41.688 692.674 43.608 689.005 47.448C685.421 51.288 683.629 56.8773 683.629 64.216V103H671.981V32.856H683.629V42.84C685.933 39.256 689.048 36.4827 692.973 34.52C696.984 32.5573 701.378 31.576 706.157 31.576Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M782.784 31.704C788.843 31.704 794.133 33.0267 798.656 35.672C803.264 38.3173 806.677 41.6453 808.896 45.656V32.856H820.672V104.536C820.672 110.936 819.307 116.611 816.576 121.56C813.845 126.595 809.92 130.52 804.8 133.336C799.765 136.152 793.877 137.56 787.136 137.56C777.92 137.56 770.24 135.384 764.096 131.032C757.952 126.68 754.325 120.749 753.216 113.24H764.736C766.016 117.507 768.661 120.92 772.672 123.48C776.683 126.125 781.504 127.448 787.136 127.448C793.536 127.448 798.741 125.443 802.752 121.432C806.848 117.421 808.896 111.789 808.896 104.536V89.816C806.592 93.912 803.179 97.3253 798.656 100.056C794.133 102.787 788.843 104.152 782.784 104.152C776.555 104.152 770.88 102.616 765.76 99.544C760.725 96.472 756.757 92.1627 753.856 86.616C750.955 81.0693 749.504 74.7547 749.504 67.672C749.504 60.504 750.955 54.232 753.856 48.856C756.757 43.3947 760.725 39.1707 765.76 36.184C770.88 33.1973 776.555 31.704 782.784 31.704ZM808.896 67.8C808.896 62.5094 807.829 57.9013 805.696 53.976C803.563 50.0507 800.661 47.064 796.992 45.016C793.408 42.8827 789.44 41.816 785.088 41.816C780.736 41.816 776.768 42.84 773.184 44.888C769.6 46.936 766.741 49.9227 764.608 53.848C762.475 57.7733 761.408 62.3813 761.408 67.672C761.408 73.048 762.475 77.7413 764.608 81.752C766.741 85.6773 769.6 88.7067 773.184 90.84C776.768 92.888 780.736 93.912 785.088 93.912C789.44 93.912 793.408 92.888 796.992 90.84C800.661 88.7067 803.563 85.6773 805.696 81.752C807.829 77.7413 808.896 73.0907 808.896 67.8Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M846.372 21.464C844.153 21.464 842.276 20.696 840.74 19.16C839.204 17.624 838.436 15.7467 838.436 13.528C838.436 11.3093 839.204 9.43201 840.74 7.89601C842.276 6.36001 844.153 5.59201 846.372 5.59201C848.505 5.59201 850.297 6.36001 851.748 7.89601C853.284 9.43201 854.052 11.3093 854.052 13.528C854.052 15.7467 853.284 17.624 851.748 19.16C850.297 20.696 848.505 21.464 846.372 21.464ZM852.004 32.856V103H840.356V32.856H852.004Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M906.032 31.576C914.565 31.576 921.477 34.1787 926.768 39.384C932.059 44.504 934.704 51.928 934.704 61.656V103H923.184V63.32C923.184 56.3227 921.435 50.9893 917.936 47.32C914.437 43.5653 909.659 41.688 903.6 41.688C897.456 41.688 892.549 43.608 888.88 47.448C885.296 51.288 883.504 56.8773 883.504 64.216V103H871.856V32.856H883.504V42.84C885.808 39.256 888.923 36.4827 892.848 34.52C896.859 32.5573 901.253 31.576 906.032 31.576Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M1017.73 65.24C1017.73 67.4587 1017.6 69.8053 1017.35 72.28H961.283C961.71 79.192 964.056 84.6107 968.323 88.536C972.675 92.376 977.923 94.296 984.067 94.296C989.102 94.296 993.283 93.144 996.611 90.84C1000.02 88.4507 1002.41 85.2933 1003.78 81.368H1016.32C1014.45 88.1093 1010.69 93.6134 1005.06 97.88C999.427 102.061 992.43 104.152 984.067 104.152C977.411 104.152 971.438 102.659 966.147 99.672C960.942 96.6853 956.846 92.4613 953.859 87C950.872 81.4533 949.379 75.0534 949.379 67.8C949.379 60.5467 950.83 54.1893 953.731 48.728C956.632 43.2667 960.686 39.0853 965.891 36.184C971.182 33.1973 977.24 31.704 984.067 31.704C990.723 31.704 996.611 33.1547 1001.73 36.056C1006.85 38.9573 1010.78 42.968 1013.51 48.088C1016.32 53.1227 1017.73 58.84 1017.73 65.24ZM1005.7 62.808C1005.7 58.3707 1004.72 54.5733 1002.76 51.416C1000.79 48.1734 998.104 45.7413 994.691 44.12C991.363 42.4133 987.651 41.56 983.555 41.56C977.667 41.56 972.632 43.4374 968.451 47.192C964.355 50.9467 962.008 56.152 961.411 62.808H1005.7Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M1097.11 65.24C1097.11 67.4587 1096.98 69.8053 1096.72 72.28H1040.66C1041.08 79.192 1043.43 84.6107 1047.7 88.536C1052.05 92.376 1057.3 94.296 1063.44 94.296C1068.48 94.296 1072.66 93.144 1075.99 90.84C1079.4 88.4507 1081.79 85.2933 1083.15 81.368H1095.7C1093.82 88.1093 1090.07 93.6134 1084.43 97.88C1078.8 102.061 1071.8 104.152 1063.44 104.152C1056.79 104.152 1050.81 102.659 1045.52 99.672C1040.32 96.6853 1036.22 92.4613 1033.23 87C1030.25 81.4533 1028.75 75.0534 1028.75 67.8C1028.75 60.5467 1030.2 54.1893 1033.11 48.728C1036.01 43.2667 1040.06 39.0853 1045.27 36.184C1050.56 33.1973 1056.62 31.704 1063.44 31.704C1070.1 31.704 1075.99 33.1547 1081.11 36.056C1086.23 38.9573 1090.15 42.968 1092.88 48.088C1095.7 53.1227 1097.11 58.84 1097.11 65.24ZM1085.07 62.808C1085.07 58.3707 1084.09 54.5733 1082.13 51.416C1080.17 48.1734 1077.48 45.7413 1074.07 44.12C1070.74 42.4133 1067.03 41.56 1062.93 41.56C1057.04 41.56 1052.01 43.4374 1047.83 47.192C1043.73 50.9467 1041.38 56.152 1040.79 62.808H1085.07Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
            <path
                d="M1124.13 44.248C1126.18 40.2373 1129.08 37.1227 1132.83 34.904C1136.67 32.6853 1141.32 31.576 1146.79 31.576V43.608H1143.71C1130.66 43.608 1124.13 50.6907 1124.13 64.856V103H1112.48V32.856H1124.13V44.248Z"
                stroke="#8536df"
                strokeWidth="5"
                mask="url(#path-1-outside-1_101_5)"
            />
        </svg>
    );
}

export default HomeSVG;
